<template lang="pug">
  section.slice-carousel.my-70.md_my-100
    header.w-full.lg_w-8x12.mx-auto.px-20.pt-50.md_pt-0.mb-40(v-if="slice.heading && slice.heading.length")
      h4.text-20.xl_text-24.md_px-20 {{ slice.heading }}

    .relative
      //- carousel
      .w-full.carousel(ref="carousel")
        //- slides...
        .carousel-cell.w-full.md_px-20(v-for="(slide, i) in slice.slides", :class="{'h-full flex items-center': imgLoaded}")
          project-image(:image="slide.image[0]", @load="onImgLoad", :lazy="i !== 0", captionType="carousel", :caption="slide.captionCustom")
      //- nav
      nav.flex.w-full.mt-5.-mb-20.md_my-0(v-if="flkty && slice.slides.length > 1")
        .md_absolute.top-0.left-0.h-full.md_w-2x12.flex.items-center.justify-center.pointer-events-none
          button.p-20.pointer-events-auto.focus_outline-none(@click="flkty.previous()")
            svg-chevron-left.h-18.md_h-40.text-black

        .flex-1.md_absolute.top-0.right-0.h-full.md_w-2x12.flex.items-center.justify-center.pointer-events-none
          //- counter
          .md_absolute.bottom-0.left-0.w-full.text-center.text-24.md_text-30.xl_text-34 {{ flkty.selectedIndex + 1 }}/{{ slice.slides.length }}

          button.p-20.pointer-events-auto.focus_outline-none(@click="flkty.next()")
            svg-chevron-right.h-18.md_h-40.text-black

</template>

<script>
import ProjectImage from '@/components/project/ProjectImage'
import Flickity from 'flickity'
export default {
  name: 'SliceCarousel',
  props: { slice: Object },
  components: { ProjectImage },
  data () {
    return {
      flkty: null,
      imgLoaded: false
    }
  },
  methods: {
    init () {
      this.flkty = new Flickity(this.$refs.carousel, {
        // cellAlign: 'left',
        draggable: this.slice.slides.length > 1,
        wrapAround: true,
        prevNextButtons: false,
        pageDots: false,
        initialIndex: 0
        // adaptiveHeight: !this.is('md') // >> too jeryky on mobile
        // autoPlay: 4000, // duration
        // pauseAutoPlayOnHover: false
      })
      // if (!this.visible) this.flkty.pausePlayer()
      // detect static clicks for routing
      // this.flkty.on('staticClick', () => { this.click = true })
      // resume autoplay after drag
      // this.flkty.on('dragEnd', () => this.flkty.playPlayer())
    },
    onImgLoad () {
      this.imgLoaded = true
      this.flkty.resize()
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style>
</style>
