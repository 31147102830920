<template lang="pug">
  figure.project-image.mx-auto.md_px-20.group.relative(:class="[width]")
    .relative.md_w-full_80.md_-ml-40
      resp-img(:image="image", fit="object-contain object-bottom md_object-center", v-bind="$attrs", v-on="$listeners")
      //- (hover caption)
      template(v-if="captionType === 'hover' && hasCaption")
        image-hover-caption(:image="image")
    //- (carousel caption - below centerd)
    template(v-if="captionType === 'carousel'")
      figcaption.text-center.text-10.xl_text-12.pt-8.md_pt-30.lg_opacity-0.lg_group-hover_opacity-100.transition.duration-150
        image-asset-caption.block(:image="image", separator=" ", :caption="true", :captionCustom="caption")
</template>

<script>
export default {
  name: 'ProjectImage',
  props: {
    image: Object,
    captionType: { type: String, default: 'hover' },
    caption: { type: String, default: undefined }
  },
  computed: {
    isLnd () {
      return this.image && this.image.width > this.image.height * 1.1
    },
    width () {
      // if (this.squareFrame) {
      //   return 'w-full md_w-8x12'
      // }
      return {
        'w-full md_w-8x12': this.isLnd,
        'w-full md_w-4x12': !this.isLnd
      }
    },
    squareFrame () {
      return this.captionType === 'carousel'
    },
    hasCaption () {
      if (this.image?.assetCaption || this.image?.courtesy || this.image?.photoCredit) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
</style>
